<template>
  <v-container grid-list-md text-xs-center class="settings-container">
    <v-layout row wrap>
      <!-- start: side bar -->
      <v-flex xs3>
        <v-card dark color="primary" class="settings-content">
          <v-card-text class="px-0 pr-0 mr-0">
            <!-- start navigation links -->
            <v-navigation-drawer class="transparent" dark permanent>
              <v-list>
                <v-list-item v-for="item in items" :key="item.title">
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>

            <!-- end: navigation links -->
          </v-card-text>
        </v-card>
      </v-flex>

      <!-- end: side bar -->

      <!-- start: content -->

      <v-flex xs9>
        <v-card dark color="grey lighten-4" style="height: 100vh">
          <v-card-text class="px-0">9</v-card-text>
        </v-card>
      </v-flex>

      <!-- end: content -->
    </v-layout>
  </v-container>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';

export default {
  extends: CSBase,

  data() {
    return {
      items: [
        { title: 'Client Settings', icon: 'dashboard' },
        { title: 'Integration', icon: 'gavel' },
        { title: 'Proforma Template', icon: 'description' },
        { title: 'Users', icon: 'account_box' },
        { title: 'Groups', icon: 'group' }
      ]
    };
  },

  async mounted() {
    const { query } = this.$route;

    this.$app.authAgent.enforceVendorOrUserAuthStrategy(this.$route, query);
  }
};
</script>
<style scoped>
.settings-container {
  width: 100%;
  height: 100%;
  max-width: unset;
  margin: 0;
  padding: 0;
}
.settings-content {
  text-align: left;
  height: 100vh;
}
</style>
